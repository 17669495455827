import { OptionType } from "components";
import * as React from "react";

interface UserData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  electricityBill: OptionType;
  fuelCost: OptionType;
  financingOption: string;
  loanDuration?: OptionType;
  package: string;
  recommendedKW: number;
}

export interface UserContextType {
  user: UserData;
  updateUserData: (data: UserData) => void;
}

const initOptionType: OptionType = {
  label: "",
  value: "",
};

export const UserContext = React.createContext<UserContextType | null>(null);

const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = React.useState<UserData>({
    firstName: "",
    lastName: "",
    email: "",
    electricityBill: initOptionType,
    fuelCost: initOptionType,
    phone: "",
    address: "",
    financingOption: "",
    package: "",
    loanDuration: undefined,
    recommendedKW: 0,
  });

  const updateUserData = (data: UserData) => {
    setUser(data);
  };

  return (
    <UserContext.Provider
      value={{
        user,
        updateUserData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider };
