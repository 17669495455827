import styles from "./styles.module.scss";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Input,
  Button,
  OptionType,
  optionTypeSchema,
  optionTypeSchemaReq,
  Select,
} from "components/form";
import {
  ArrowCircleRightFill,
  ArrowLeft,
  EmptyWalletIcon,
  FlashIcon,
  HouseIcon,
  LocationPointer,
  SunIcon,
} from "assets";
import { useEffect, useState } from "react";

export interface RoofFormData {
  address: string;
  bill: OptionType;
  gen: OptionType;
}

const roofSchema = yup.object({
  address: yup.string().required("Required"),
  bill: optionTypeSchemaReq,
  gen: optionTypeSchemaReq,
});

interface RoofAnalysisFormProps {
  back: () => void;
  submit: (data: RoofFormData) => void;
  initData: RoofFormData | undefined;
}

const RoofAnalysisForm: React.FC<RoofAnalysisFormProps> = ({
  back,
  submit,
  initData,
}) => {
  const [addressSearch, setAddressSearch] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    setValue,
    reset,
  } = useForm<RoofFormData>({
    resolver: yupResolver(roofSchema),
  });

  useEffect(() => {
    if (initData) {
      setAddressSearch(true);
      reset(initData);
    }
  }, [initData]);

  const onSubmit: SubmitHandler<RoofFormData> = (data) => {
    submit(data);
  };
  const billOptions = [
    {
      label: "Less than ₦50,000",
      value: "Less than ₦50,000",
    },
    {
      label: "₦50,000 -  ₦100,000",
      value: "₦50,000 -  ₦100,000",
    },
    {
      label: "Over ₦100,000",
      value: "Over ₦100,000",
    },
  ];

  const showRec = watch("bill") && watch("gen");

  return (
    <>
      <section className={styles.container}>
        <ArrowLeft onClick={back} role="button" className={styles.back} />
        <h1 className={styles.ttl}>
          {addressSearch
            ? "Roof Analysis"
            : "Let’s get to know your electricity need. "}
        </h1>
        <p className={styles.txt}>
          {addressSearch
            ? ` Your roof has been analyzed Now, let's tailor your solar solution to
            optimize energy efficiency for your unique location and home`
            : "Share your location for tailored solar solutions! By providing your address, we can customize our recommendations to maximize solar efficiency based on your local climate and energy needs."}
        </p>
        <form className={styles.form}>
          <Input
            label="Address"
            type="text"
            required
            validatorMessage={errors.address?.message}
            name="address"
            register={register}
            parentClassName={styles.form__inputWrap}
            value={watch("address")}
            icon={<LocationPointer style={{ width: "16px", height: "16px" }} />}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setAddressSearch(true);
              }
            }}
          />

          {addressSearch && (
            <>
              <div className={styles.infoSec}>
                <div className={styles.info}>
                  <SunIcon />
                  <div>
                    <p className={styles.info__txt1}>
                      1,957 hours of usable sunlight per year
                    </p>
                    <p className={styles.info__txt2}>
                      Based on day-to-day analysis of weather patterns
                    </p>
                  </div>
                </div>
                <div className={styles.info}>
                  <HouseIcon />
                  <div>
                    <p className={styles.info__txt1}>27,414 Sq Feet</p>
                    <p className={styles.info__txt2}>
                      Based On 3D Modeling of year roof and nearby trees
                    </p>
                  </div>
                </div>
                <div className={styles.info}>
                  <EmptyWalletIcon />
                  <div>
                    <p className={styles.info__txt1}>₦16,000,000 Savings</p>
                    <p className={styles.info__txt2}>
                      Estimated net savings for your roof over 20 years
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.bill}>
                <p className={styles.bill__ttl}>Electricity bill details</p>
                <p className={styles.bill__txt}>
                  Fine-tune your information to find out how much you could
                  save.
                </p>
              </div>
              <Select
                label="Average Electricity Bill Per Month"
                value={watch("bill")}
                placeholder={"Please select..."}
                validatorMessage={
                  errors.bill?.value?.message?.toString() ??
                  errors.bill?.message ??
                  ""
                }
                options={billOptions}
                onChange={(val) => setValue("bill", val)}
                icon={<FlashIcon style={{ width: "20px", height: "20px" }} />}
              />
              <Select
                label="Average Generator Fuel Cost Per Month"
                value={watch("gen")}
                placeholder={"Please select..."}
                validatorMessage={
                  errors.gen?.value?.message?.toString() ??
                  errors.gen?.message ??
                  ""
                }
                options={billOptions}
                onChange={(val) => setValue("gen", val)}
                icon={<FlashIcon style={{ width: "20px", height: "20px" }} />}
              />
              {showRec && (
                <div className={styles.solarRec}>
                  <p className={styles.solarRec__ttl}>
                    Recommended Solar Installation Size
                  </p>
                  <p className={styles.solarRec__txt1}>7.0 kW</p>
                  <p className={styles.solarRec__txt2}>(493 ft2)</p>
                  <p className={styles.solarRec__txt3}>
                    This size will cover about 99% of your electricity usage.
                    Solar installations are sized in kilowatts (kW).
                  </p>
                </div>
              )}
            </>
          )}

          <Button
            onClick={
              addressSearch
                ? handleSubmit(onSubmit)
                : () => setAddressSearch(true)
            }
            className={styles.form__btn}
            variant="blue-fill"
          >
            {addressSearch ? "Let’s go" : "Continue"} <ArrowCircleRightFill />
          </Button>
        </form>
      </section>
    </>
  );
};

export { RoofAnalysisForm };
