import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import styles from "./styles.module.scss";
import { Spinner } from "assets";

interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant: "blue-fill";

  isLoading?: boolean;
}

const Button = (props: ButtonProps) => {
  const { onClick, variant, children, className, isLoading } = props;
  return (
    <>
      <button
        {...props}
        className={`${className} ${styles.btn} ${styles[`btn--${variant}`]} ${
          isLoading ? styles[`btn--loading`] : ""
        }`}
        onClick={(e) => {
          e.preventDefault();
          onClick && onClick(e);
        }}
      >
        {children}{" "}
        {isLoading ? <Spinner className={styles.btn__spinner} /> : ""}
      </button>
    </>
  );
};

export { Button };
