import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import styles from "./styles.module.scss";

interface InputProps {
  name?: string;
  dataTestID?: string;
  placeholder: string;
  className?: string;
  parentClassName?: string;
  required?: boolean;
  minLength?: number;
  validatorMessage: string | undefined;
  label?: string;
  onChange: (value: string) => void;
  readOnly?: boolean;
  value?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  showRequired?: boolean;
  disabled?: boolean;
}

const CustomPhoneInput: React.FC<InputProps> = ({
  dataTestID,
  placeholder,
  className,
  parentClassName,
  validatorMessage,
  label,
  onChange,
  value,
  showRequired,
  disabled,
  name,
}) => {
  return (
    <>
      <div
        className={`${styles.inputWrapper} ${parentClassName} ${
          validatorMessage ? styles.error : ""
        }`}
      >
        {label && (
          <label className={styles.label}>
            {label}
            {showRequired ? <span className={styles.req}>*</span> : ""}
          </label>
        )}

        <PhoneInput
          country="ng"
          dropdownStyle={{
            fontFamily: "Lexend Deca",
            fontWeight: 400,
          }}
          autocompleteSearch
          countryCodeEditable={false}
          placeholder={placeholder}
          inputClass={styles.input}
          inputProps={{
            name,
            required: true,
            disabled,
          }}
          onChange={(value) => {
            onChange(value);
          }}
          value={value}
        />

        {validatorMessage && (
          <small className={styles.message}>{validatorMessage}</small>
        )}
      </div>
    </>
  );
};
export { CustomPhoneInput };
