import { RecommendationsUI } from "modules";

const Recommendations = () => {
  return (
    <>
      <RecommendationsUI />
    </>
  );
};

export { Recommendations };
