import styles from "./styles.module.scss";
import * as yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CustomPhoneInput, Input, Button } from "components/form";
import { ArrowCircleRightFill, CheckBoxFill, MailIcon, UserIcon } from "assets";
import { useEffect } from "react";

export interface ContactData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  agreement: boolean;
}

const phoneNumberSchemaReq = yup
  .string()
  .required("Required")
  .matches(
    /^\+?\d{1,}$/,
    "Only numbers and `+` are allowed. E.g. +2348122334455 or 08122334455"
  )
  .min(10, "Enter a valid phone number");

const schema = yup.object().shape({
  email: yup.string().required("Required").email("Enter a valid email"),
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  phone: phoneNumberSchemaReq,
  agreement: yup.boolean().required("You have to agree to continue"),
});

interface ContactFormProps {
  submit: (data: ContactData) => void;
  initData: ContactData | undefined;
}

const ContactForm: React.FC<ContactFormProps> = ({ submit, initData }) => {
  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    setValue,
    reset,
  } = useForm<ContactData>({
    resolver: yupResolver(schema),
    // defaultValues: initLoginData,
  });

  useEffect(() => {
    initData && reset(initData);
  }, [initData]);

  const onSubmit: SubmitHandler<ContactData> = (data) => {
    submit(data);
  };
  return (
    <>
      <section className={styles.container}>
        <h1 className={styles.ttl}>Let’s get to know you better</h1>
        <p className={styles.txt}>
          Ready to power up your world? Let's shed some light on your
          preferences and needs to find the perfect solar solution for you.
        </p>
        <form className={styles.form}>
          <Input
            label="First Name"
            type="text"
            required
            validatorMessage={errors.firstName?.message}
            name="firstName"
            register={register}
            parentClassName={`${styles.form__inputWrap} ${styles.form__halfInput}`}
            value={watch("firstName")}
            icon={<UserIcon />}
          />
          <Input
            label="Last Name"
            type="text"
            required
            validatorMessage={errors.lastName?.message}
            name="lastName"
            register={register}
            parentClassName={`${styles.form__inputWrap} ${styles.form__halfInput}`}
            value={watch("lastName")}
            icon={<UserIcon />}
          />
          <Input
            label="Email Address"
            type="email"
            required
            validatorMessage={errors.email?.message}
            name="email"
            register={register}
            parentClassName={styles.form__inputWrap}
            value={watch("email")}
            icon={<MailIcon />}
          />
          <CustomPhoneInput
            label="Phone Number"
            placeholder="Phone Number"
            name="phone"
            validatorMessage={errors.phone?.message}
            onChange={(val) => setValue("phone", val)}
            parentClassName={styles.form__inputWrap}
            value={watch("phone")}
          />

          <label
            className={`${styles.agreement} ${
              errors.agreement?.message ? styles["agreement--error"] : ""
            }`}
            htmlFor="agreement"
          >
            <input
              onChange={() => setValue("agreement", !watch("agreement"))}
              style={{ display: "none" }}
              checked={watch("agreement")}
              id="agreement"
              type="checkbox"
            />{" "}
            {watch("agreement") ? <CheckBoxFill /> : <span></span>}
            <p>
              I agree to the transfer and processing of my data and the privacy
              policy
            </p>
          </label>
          {/* <p className={styles.error}>{errors.agreement?.message}</p> */}
          <Button
            onClick={handleSubmit(onSubmit)}
            className={styles.form__btn}
            variant="blue-fill"
          >
            Let’s go <ArrowCircleRightFill />
          </Button>
        </form>
      </section>
    </>
  );
};

export { ContactForm };
