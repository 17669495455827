"use client";
import { useClickOutside } from "hooks";
import styles from "./styles.module.scss";
import { ReactNode, useRef } from "react";

interface ModalProps {
  children: ReactNode;
  close: () => void;
  show: boolean;
  dialogClassName?: string;
  contentClassName?: string;
}

const Modal: React.FC<ModalProps> = ({
  children,
  close,
  show,
  dialogClassName,
  contentClassName,
}) => {
  const modalRef = useRef(null);
  useClickOutside(modalRef, close);

  if (!show) return null;

  return (
    <aside className={`${styles.modalDialog} ${dialogClassName}`}>
      <section
        ref={modalRef}
        className={`${styles.modalContent} ${contentClassName}`}
      >
        {children}
      </section>
    </aside>
  );
};

export { Modal };
