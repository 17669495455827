import styles from "./styles.module.scss";
import { useContext, useEffect, useState } from "react";
import { ContactData, ContactForm } from "./contactForm";
import { RoofAnalysisForm, RoofFormData } from "./roofAnalysisForm";
import { UserContext, UserContextType } from "context/user";

interface HomeUIProps {
  handleRecs: () => void;
}

const HomeUI: React.FC<HomeUIProps> = ({ handleRecs }) => {
  const { updateUserData, user } = useContext(UserContext) as UserContextType;
console.log(user)
  const [stage, setStage] = useState(1);
  const [contactInfo, setContactInfo] = useState<ContactData | undefined>(
    undefined
  );
  const [roofInfo, setRoofInfo] = useState<RoofFormData | undefined>(undefined);

  useEffect(() => {
    if (user && user.firstName !== "") {
      setContactInfo({ ...user, agreement: true });
      setRoofInfo({
        bill: user.electricityBill,
        gen: user.fuelCost,
        address: user.address,
      });
    }
  }, [user]);

  const handleBack = () => {
    setStage(1);
  };

  const handleContact = (data: ContactData) => {
    setContactInfo(data);
    setStage(2);
  };

  const handleRoof = (data: RoofFormData) => {
    setRoofInfo(data);
    handleRecs();
    contactInfo &&
      updateUserData({
        ...user,
        firstName: contactInfo?.firstName,
        lastName: contactInfo.lastName,
        email: contactInfo.email,
        electricityBill: data.bill,
        fuelCost: data.gen,
        phone: contactInfo.phone,
        address: data.address,
        recommendedKW: 7
      });
  };
  return (
    <>
      {stage === 1 ? (
        <ContactForm initData={contactInfo} submit={handleContact} />
      ) : (
        <RoofAnalysisForm
          initData={roofInfo}
          back={handleBack}
          submit={handleRoof}
        />
      )}
    </>
  );
};

export { HomeUI };
