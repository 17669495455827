import { HomeUI } from "modules";
import { useNavigate } from "react-router-dom";
import { Routes } from "router";

const Home = () => {
  const navigate = useNavigate();

  const handleRecs = () => navigate(Routes.recommendations);
  
  return (
    <>
      <HomeUI handleRecs={handleRecs} />
    </>
  );
};

export { Home };
