import { Modal } from "components";
import styles from "./styles.module.scss";
import { CheckBoxFill, CloseOutlineIcon, panel1 } from "assets";
import { useState } from "react";

interface Props {
  close: () => void;
  show: boolean;
}

const SolarPackageModal: React.FC<Props> = ({ show, close }) => {
  const [addOn, setAddOn] = useState(false);
  return (
    <>
      <Modal contentClassName={styles.modalContent} show={show} close={close}>
        <CloseOutlineIcon
          className={styles.close}
          role="button"
          onClick={close}
        />
        <section className={styles.imgSec}>
          <div className={styles.imgSec__bar}>
            <div className={styles.active}>
              <img src={panel1} alt="" />
            </div>
            <div>
              <img src={panel1} alt="" />
            </div>
            <div>
              <img src={panel1} alt="" />
            </div>
          </div>
          <img className={styles.imgSec__img} src={panel1} alt="" />
        </section>
        <section className={styles.txtSec}>
          <p className={styles.txtSec__title}>
            2Kw off grid solar system with 6-8 hours backup.
          </p>
          <div className={styles.txtSec__descrip}>
            <p className={styles.txtSec__descrip__ttl}>Description</p>
            <p className={styles.txtSec__descrip__txt}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doin
              in eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Utasi enim ad minim veniam, quis nostrud exercitation ullamco
              laborisis nisi ut aliquip ex ea commodo consequat. Duis aute irure
              dolor in a reprehenderit in voluptate velit esse cillum dolore eu
              fugiat nullami pariatur. Excepteur sint occaecat cupidatat non
              proident, sunt inai culpa qui officia deserunt mollit anim id est
              laborum.
            </p>
          </div>
          <p className={styles.txtSec__price}>₦320,500</p>
          <div className={styles.addOn} >
            <p className={styles.addOn__ttl}>Add-On</p>
            <div className={styles.addOn__item}>
              <label className={`${styles.agreement} `} htmlFor="agreement">
                <input
                  onChange={() => setAddOn((prev) => !prev)}
                  style={{ display: "none" }}
                  checked={addOn}
                  id="agreement"
                  type="checkbox"
                />{" "}
                {addOn ? <CheckBoxFill /> : <span></span>}
                <p>Solar Panel Installer</p>
              </label>
              <p>₦50,000</p>
            </div>
          </div>
        </section>
      </Modal>
    </>
  );
};

export { SolarPackageModal };
