import {
  ArrowCircleLeft,
  ArrowCircleRightFill,
  ArrowLeft,
  CalendarBlankIcon,
  logo,
  panel1,
  RadioFillBlue,
  RadioOutlineGrey,
} from "assets";
import styles from "./styles.module.scss";
import { useContext, useState } from "react";
import { Button, OptionType, Select, SolarPackageModal } from "components";
import { useNavigate } from "react-router-dom";
import { UserContext, UserContextType } from "context/user";
import { Toast, ToastData } from "components/toast";

const RecommendationsUI = () => {
  const { user, updateUserData } = useContext(UserContext) as UserContextType;

  const navigate = useNavigate();

  const [selectedPanel, setSelectedPanel] = useState("");
  const [option, setOption] = useState("buy");
  const [duration, setDuration] = useState<OptionType | undefined>(undefined);
  const [showPackage, setShowPackage] = useState(false);
  const [errors, setErrors] = useState({
    duration: undefined,
    panel: undefined,
  });
  const [toast, setToast] = useState<ToastData>({
    show: false,
    heading: "",
    text: "",
    type: "info",
  });

  const durationOptions: OptionType[] = [
    {
      label: "12 months",
      value: 12,
    },
    {
      label: "36 months",
      value: 36,
    },
    {
      label: "2 years",
      value: 24,
    },
    {
      label: "5 years",
      value: 60,
    },
  ];

  const panels: PanelData[] = [
    {
      title: "2Kw off grid solar system with 6-8 hours backup.",
      cost: 320500,
      image: panel1,
      id: "2kw",
      size: 2,
    },
    {
      title: "5Kw off grid solar system with 6-8 hours backup.",
      cost: 420500,
      image: panel1,
      id: "5kw",
      size: 5,
    },
    {
      title: "8Kw off grid solar system with 6-8 hours backup.",
      cost: 720500,
      image: panel1,
      id: "8kw",
      size: 8,
    },
    {
      title: "10Kw off grid solar system with 6-8 hours backup.",
      cost: 920500,
      image: panel1,
      id: "10Kw",
      size: 10,
    },
  ];

  const panel = panels.find((item) => item.id === selectedPanel);
  const panelCost = panels.find((item) => item.id === selectedPanel)?.cost;
  const upfrontPayment =
    duration?.value > 12 && panelCost ? 0.3 * panelCost : 0;
  const repayment =
    duration && panelCost
      ? duration?.value > 12
        ? (0.7 * panelCost) / duration?.value
        : panelCost / duration?.value
      : 0;

  const callLink =
    "https://outlook.office365.com/owa/calendar/BookanIntroCall@greensol.io/bookings/";

  const onSubmit = () => {
    const errors: any = {};
    if (!panel) {
      errors.panel = "Select a solar package";
      // toast
      setToast({
        show: true,
        heading: "No package selected",
        text: "Please select a solar package",
        type: "error",
      });
      setTimeout(() => {
        setToast({
          show: false,
          heading: "",
          text: "",
          type: "info",
        });
      }, 2000);
    }
    if ((!duration || duration?.value === "") && option === "loan") {
      errors.duration = "Select a duration";
    }
    setErrors(errors);
    if (Object.keys(errors).length === 0 && panel) {
      setErrors({ duration: undefined, panel: undefined });
      updateUserData({
        ...user,
        financingOption: option,
        loanDuration: option === "loan" ? duration : undefined,
        package: panel.id,
      });

      setToast({
        show: true,
        heading: "Awesome!",
        text: "Thank you for sharing your information, we're redirecting you to the bookings page in 3...2..1",
        type: "success",
      });

      setTimeout(() => {
        window.location.assign(callLink);
      }, 1500);
    }
  };

  const compatiblePanels = panels
    .filter((item) => item.size > user.recommendedKW)
    .map((item) => item.size);

  const recommendedSize = Math.min(...compatiblePanels);

  const formatAmount = (amt: number) =>
    parseFloat(amt.toFixed(2)).toLocaleString();

  return (
    <>
      <Toast {...toast} />
      <SolarPackageModal
        show={showPackage}
        close={() => setShowPackage(false)}
      />
      <main className={styles.main}>
        <section className={styles.header}>
          <img className={styles.header__logo} src={logo} alt="" />
          <ArrowLeft
            onClick={() => navigate(-1)}
            role="button"
            className={styles.header__back}
          />
          <h1 className={styles.header__ttl}>Recommended solar panels</h1>
          <p className={styles.header__txt}>
            Explore our selection tailored to your needs and discover the
            perfect fit for your home or business
          </p>
        </section>
        <section className={styles.content}>
          {/* <div className={styles.content__ctrl}>
            <ArrowCircleLeft />
            <ArrowCircleLeft />
          </div> */}
          <div className={styles.panels}>
            {panels.map((item) => (
              <Panel
                {...item}
                selected={item.id === selectedPanel}
                onSelect={() => setSelectedPanel(item.id)}
                handleView={() => setShowPackage(true)}
                recommended={item.size === recommendedSize}
              />
            ))}
          </div>
        </section>
        <section className={styles.financing}>
          <div className={styles.financing__options}>
            <button
              onClick={() => setOption("buy")}
              className={option === "buy" ? styles.active : ""}
            >
              Buy
            </button>
            <button
              onClick={() => setOption("loan")}
              className={option === "loan" ? styles.active : ""}
            >
              Financing with Greensol
            </button>
          </div>
          {option === "buy" ? (
            <div>
              <div className={styles.address}>
                <p className={styles.address__ttl}>Delivery Address</p>
                <p className={styles.address__txt}>{user.address}</p>
                <p className={styles.address__txt}>{user.phone}</p>
              </div>
              <div className={styles.cost}>
                <span>Solar Panel Installer:</span>
                <span>ScandPoint</span>
              </div>
              <div className={styles.cost}>
                <span>Total:</span>
                <span>₦{panelCost ? formatAmount(panelCost) : "---"}</span>
              </div>
            </div>
          ) : (
            <div className={styles.loan}>
              <p className={styles.loan__ttl}>Loan powered by Greensol</p>
              <p className={styles.loan__txt}>
                A loan is a great way to take advantage of the incentives and
                long-term savings of a solar system without providing all of the
                cash up front. Every month, you will make payments to re-pay
                your loan principal and interest.
              </p>
              <Select
                label="Repayment Duration"
                value={duration}
                placeholder={"Please select..."}
                validatorMessage={errors.duration}
                options={durationOptions}
                onChange={setDuration}
                icon={
                  <CalendarBlankIcon
                    style={{ width: "20px", height: "20px" }}
                  />
                }
              />
              <div className={styles.cost}>
                <span>Upfront Payment :</span>
                <span>₦{formatAmount(upfrontPayment)}</span>
              </div>
              <div className={styles.cost}>
                <span>Transaction fee :</span>
                <span>2%</span>
              </div>
              <div className={styles.cost}>
                <span>Repayment Per Month :</span>
                <span>₦{formatAmount(repayment * 1.02)}</span>
              </div>
            </div>
          )}
          <Button
            onClick={() => onSubmit()}
            // https://outlook.office365.com/owa/calendar/BookanIntroCall@greensol.io/bookings/
            className={styles.btn}
            variant="blue-fill"
          >
            Book a call <ArrowCircleRightFill />
          </Button>
        </section>
      </main>
    </>
  );
};
interface PanelData {
  title: string;
  cost: number;
  image: string;
  id: string;
  size: number;
}
interface PanelProps extends PanelData {
  selected: boolean;
  recommended: boolean;
  onSelect: () => void;
  handleView: () => void;
}
const Panel = ({
  selected,
  title,
  image,
  cost,
  onSelect,
  handleView,
  recommended,
}: PanelProps) => {
  return (
    <div
      className={`${styles.panel} ${recommended ? styles["recommended"] : ""}`}
    >
      {recommended && <p className={styles.recommended__txt}>Recommended</p>}
      <div role="button" onClick={onSelect} className={styles.panel__imgSec}>
        {!selected ? <RadioOutlineGrey /> : <RadioFillBlue />}
        <img src={image} alt="" />
      </div>
      <p onClick={handleView} className={styles.panel__name}>
        {title}
      </p>
      <p className={styles.panel__price}>₦{cost.toLocaleString()}</p>
    </div>
  );
};

export { RecommendationsUI };
