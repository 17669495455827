import { ArrowCircleLeft, StarFilledGold } from "assets/vectors";
import styles from "./styles.module.scss";
import { logo } from "assets";

const ContactLayout = ({ children }) => {
  return (
    <>
      <main className={styles.main}>
        <aside className={styles.aside}>
          <div className={styles.card}>
            <p className={styles.card__txt}>
              Thanks to GreenSol, our transition to solar power was effortless.
              Not only are we saving on energy costs, but we're also making a
              positive impact on the environment. The team's professionalism and
              top-quality panels exceeded our expectations. We couldn't be
              happier with our decision
            </p>
            <div className={styles.card__user}>
              <div className={styles.card__profile}>
                <p className={styles.card__profile__name}>Amelia George</p>
                <p className={styles.card__profile__role}>
                  Business Analyst, Microsoft
                </p>
                <p className={styles.card__profile__other}>Work from home</p>
              </div>
              <div>
                <div className={styles.card__rating}>
                  <StarFilledGold />
                  <StarFilledGold />
                  <StarFilledGold />
                  <StarFilledGold />
                  <StarFilledGold />
                </div>
                <div className={styles.card__arrows}>
                  <ArrowCircleLeft />
                  <ArrowCircleLeft />
                </div>
              </div>
            </div>
          </div>
        </aside>
        <section className={styles.content} >
          <img className={styles.content__logo} src={logo} alt="logo" />
          {children}
        </section>
      </main>
    </>
  );
};

export { ContactLayout };
